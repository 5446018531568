@import "../../scss/";

.commentBox {
  max-width: 380px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 30px 14px;
  background: white;
  .commentBox_title {
    font-size: 1.25rem;
    color: $x-nord1;
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon_container {
      display: flex;
      gap: 6px;
    }
  }
  .commentBox_editor {
    width: 100%;
    transition: 100ms linear;
    border-radius: 12px;
    border: 1px solid $nord4;
    padding: 14px;
    resize: none;
    overflow: hidden;
    &:hover,
    &:focus {
      border-color: $x-nord2;
    }
  }
  .commentBox_editbtn {
    cursor: pointer;
    color: $x-nord1;
  }
  .commentBox_comment {
    font-size: 1rem;
    color: $nord0;
    width: 100%;
    text-align: left;
    background: $nord6;
    border-radius: 10px;
    padding: 12px;
    color: $nord2;
    word-break: break-word;
  }
  .commentBox_group {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}

.red {
  color: $nord11 !important;
}

.green {
  color: $nord14 !important;
}

.grey {
  color: $x-nord1 !important;
}
